import React from 'react'
import styled from 'styled-components'
import ImageGps from '../../util/images/image-gps.png'
import { useTranslation } from 'react-i18next'
import util from '../../util'
import { useSmallScreen } from '../../components/common/Screens'

const NearMeInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 24px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  & p {
    margin: 0;
    color: #696984;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }

  & span {
    color: #ff7d0e;
  }
`

const NearMeInfoContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;

  & span {
    color: #ff7d0e;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
`

export function NearMeInfo({ city }) {
  const { t: TRANSLATOR } = useTranslation()
  const isMobile = useSmallScreen()

  if (isMobile) return null

  return (
    <NearMeInfoContainer>
      <img src={ImageGps} alt='Location' height={20} width={20} />
      <p>
        {TRANSLATOR('carListing:nearMe')} <span>{city}</span>
      </p>
    </NearMeInfoContainer>
  )
}

export function NearMeInfoMobile({ city }) {
  const isMobile = useSmallScreen()

  if (!isMobile) return null

  return (
    <NearMeInfoContainerMobile>
      <img src={ImageGps} alt='Location' height={20} width={20} />
      <span>{city}</span>
    </NearMeInfoContainerMobile>
  )
}

export const MobileHeaderBar = styled(({ ...props }) => {
  if (!props.nearMePicked) return null
  return (
    <div className={props.className}>
      <p>
        <img src={props.location} alt='Location' />
        {props.pickupLocation?.prettyName}
      </p>
      <p>
        <img src={props.location} alt='Location' />
        {props.dropOffLocation?.prettyName}
      </p>
      <p>
        <img src={props.calendar} alt='Calendar' />
        {util.formatDateWithoutYearWithDay(props.pickupDateTime, props.language)} -{' '}
        {util.formatDateWithoutYearWithDay(props.dropOffDateTime, props.language)}
      </p>
    </div>
  )
})`
  @media screen and (min-width: ${(props) => props.breakpoint}) {
    display: none;
  }
  position: fixed;
  top: 0;
  width: 100%;
  background: #273c75;
  padding: 8px 16px;
  & p {
    display: flex;
    align-items: center;
    gap: 4px;
    color: white;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: normal;
  }
  & img {
    width: 16px;
    height: 16px;
  }
`
