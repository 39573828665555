import React, { Component } from 'react'
import Downshift from 'downshift'
import { connect } from 'react-redux'
import debug from 'debug'
import { InputDS, LabelDS, MenuDS, MenuPlaceholderDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl } from '../../layout'
import { selectSearchArgs } from '../../../redux/selectors'
import { filterDropOffLocations, formatItem, loadLocationItems } from '../../../util/searchArgs'
import Loading from '../../Loading'
import { Location, Plane } from '../../svg'
import {
  setSearchDropOffLocation,
  setSearchPickupLocation,
  setLocationSearchItems,
  startLocationSearch
} from '../../../redux/slices'
import GA from '../../../services/GA'
import debounce from 'lodash/debounce'
import trim from 'lodash/trim'

const log = debug('carla:search')

class RawLocationForm extends Component {
  state = {
    current: null, // current focused field of location range (from|to|null)
    inputValue: ''
  }

  isFocused = (key) => this.state.current === key // effects SelectDS

  handleInputFocus = (key) => (e) => {
    this.setState({ current: key, inputValue: '' })
  }
  handleInputBlur = (e) => {
    if (e.relatedTarget && e.relatedTarget.id === 'near-me-button') return
    this.setState({ current: null, inputValue: '' })
  }

  handleLocationChange = (val) => {
    if (this.isFocused('from')) this.props.setSearchPickupLocation(val)
    if (this.isFocused('to')) this.props.setSearchDropOffLocation(val)
    this.setState({ current: null, inputValue: '' })
    this.pickupInput.blur()
    this.dropOffInput.blur()
  }

  // @TODO DRY
  handleInputChange = (e) => {
    const inputValue = e.target.value
    this.setState({ inputValue })
    const input = trim(inputValue)

    this.requestAPI(input)
  }

  // call item loading functions with 300ms delay / @TODO DRY
  requestAPI = debounce(
    (input) => {
      if (!input || !input.trim()) return
      this.props.startLocationSearch()
      loadLocationItems(input)
        .then((items) => {
          if (items && items.length > 0) {
            this.props.setLocationSearchItems(items)
          } else {
            log('no location found', items)
            GA.noLocationFound()
            this.props.setLocationSearchItems(null)
          }
        })
        .catch((err) => {
          log('error during car search', err)
          this.props.setLocationSearchItems(null)
        })
    },
    500,
    { trailing: true, leading: false }
  )

  render() {
    const { locationSearch, pickupLocation, dropOffLocation, optimize, history, ...props } = this.props
    const { items, isLoading, error } = locationSearch

    const filteredItems = this.isFocused('to') ? filterDropOffLocations(items) : items
    // const selectedItem = this.isFocused('from') ? pickupLocation : dropOffLocation
    const isOpen = Boolean(this.state.current)

    return (
      <Downshift
        onChange={this.handleLocationChange}
        items={filteredItems}
        itemToString={(item) => (item ? item.label : '')}
        isOpen={isOpen}
        {...props}
      >
        {({ getRootProps, getMenuProps, getItemProps, getInputProps }) => {
          let pickupInput = ''
          let dropOffInput = ''
          if (pickupLocation) pickupInput = pickupLocation.label
          if (dropOffLocation) dropOffInput = dropOffLocation.label
          if (pickupLocation && dropOffLocation && pickupLocation.label === dropOffLocation.label) {
            dropOffInput = 'Same location'
          }
          if (this.isFocused('from')) pickupInput = this.state.inputValue
          if (this.isFocused('to')) dropOffInput = this.state.inputValue

          const pickupFormClass = `form-field left ${this.isFocused('from') ? 'focus' : ''}`
          const dropOffFormClass = `form-field right ${this.isFocused('to') ? 'focus' : ''}`

          return (
            <SelectContainerDS {...getRootProps()}>
              <div className='location-form-container'>
                <FlexEl width={1}>
                  <El width={0.5} className={pickupFormClass}>
                    <LabelDS>Pickup location</LabelDS>
                    <InputDS
                      {...getInputProps()}
                      width={1}
                      placeholder='Enter city or airport'
                      value={pickupInput}
                      innerRef={(node) => (this.pickupInput = node)}
                      onFocus={this.handleInputFocus('from')}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputBlur}
                    />
                  </El>
                  <El width={0.5} className={dropOffFormClass}>
                    <LabelDS>Drop off location</LabelDS>
                    <InputDS
                      {...getInputProps()}
                      width={1}
                      placeholder='Enter city or airport'
                      value={dropOffInput}
                      innerRef={(node) => (this.dropOffInput = node)}
                      onFocus={this.handleInputFocus('to')}
                      onChange={this.handleInputChange}
                      onBlur={this.handleInputBlur}
                    />
                  </El>
                </FlexEl>
              </div>
              {isOpen && (
                <MenuDS isOpen={isOpen} as='ul' className='items' {...getMenuProps()}>
                  {isLoading && (
                    <MenuPlaceholderDS>
                      <Loading />
                    </MenuPlaceholderDS>
                  )}
                  {!isLoading && (error || (this.state.inputValue && !items)) && (
                    <MenuPlaceholderDS>No location found</MenuPlaceholderDS>
                  )}
                  {filteredItems &&
                    filteredItems.map((item, index) => (
                      <li className='item' {...getItemProps({ key: item.value, item, index })}>
                        <FlexEl justifyContent='space-between'>
                          <El>{formatItem(item, this.state.inputValue)}</El>
                          <El>{item.type === 'airport' ? <Plane /> : <Location />}</El>
                        </FlexEl>
                      </li>
                    ))}
                </MenuDS>
              )}
            </SelectContainerDS>
          )
        }}
      </Downshift>
    )
  }
}

const LocationForm = connect(selectSearchArgs, {
  setSearchPickupLocation,
  setSearchDropOffLocation,
  startLocationSearch,
  setLocationSearchItems
})(RawLocationForm)

export default LocationForm
