import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  error: null, // TODO: why error here
  response: null
}

export const reservationInfoRetrievalSlice = createSlice({
  name: 'reservationInfoRetrievalSlice',
  initialState,
  reducers: {
    setReservationInfoResponse: (state, action) => {
      state.response = action.payload
    },
    removeReservationInfoResponse: (state) => {
      state = initialState
      return state
    }
  }
})

export const { setReservationInfoResponse, removeReservationInfoResponse } = reservationInfoRetrievalSlice.actions

export default reservationInfoRetrievalSlice.reducer
