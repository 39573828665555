import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import loadable from '@loadable/component'
import 'react-day-picker/lib/style.css'

const Loader = loadable.lib(() => import('react-day-picker'))

const locales = {
  it: {
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    weekdaysShort: ['Do', 'Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa']
  },
  de: {
    months: [
      'Januar',
      'Februar',
      'Maerz',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember'
    ],
    weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
  },
  fr: {
    months: [
      'Janvier',
      'Fevrier',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Decembre'
    ],
    weekdaysShort: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
  },
  es: {
    months: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ],
    weekdaysShort: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa']
  },
  en: {
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']
  }
}

function ReactDayPicker(props) {
  const language = useSelector((state) => state.user.language)
  const [locale, setLocale] = useState(language)

  useEffect(() => {
    setLocale(language)
  }, [language])

  return (
    <Loader fallback={null}>
      {({ default: DayPicker }) => (
        <DayPicker
          {...props}
          key={locale}
          months={locales[locale].months}
          weekdaysShort={locales[locale].weekdaysShort}
          locale={locale}
        />
      )}
    </Loader>
  )
}

export default ReactDayPicker
