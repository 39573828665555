import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Downshift from 'downshift'
import { CheckboxDownDS, InputDS, LabelDS, MenuDS, SelectContainerDS } from '../../CarlaSelect'
import { El, FlexEl, HrEl, SearchButton } from '../../layout'
import util from '../../../util'
import TimeSlider from './TimeSlider'
import DateRangePicker from '../../DatePicker/DateRangePicker'
import { setSearchDropOffTime, setSearchPickupTime } from '../../../redux/slices'
import { selectSearchArgs } from '../../../redux/selectors'
import GA from '../../../services/GA'

// do not close date range picker when user switched
const downshiftStateReducer = (state, changes) => {
  if (changes.type === Downshift.stateChangeTypes.clickButton) {
    return {
      ...changes,
      isOpen: true
    }
  }
  return changes
}

const DateTimeRangeForm = styled(
  class extends Component {
    render() {
      const {
        pickupTime,
        dropOffTime,
        pickupDateTime,
        dropOffDateTime,
        updateSearchDateRange,
        setSearchPickupTime,
        setSearchDropOffTime,
        ...props
      } = this.props

      let inputValue = 'Select time'
      if (pickupDateTime && dropOffDateTime) {
        inputValue = util.formatDateTimeRange(pickupDateTime, dropOffDateTime)
      }

      return (
        <Downshift onChange={updateSearchDateRange} stateReducer={downshiftStateReducer} {...props}>
          {({ isOpen, closeMenu, getRootProps, getLabelProps, getInputProps, getMenuProps, getToggleButtonProps }) => {
            return (
              <SelectContainerDS {...getRootProps()} {...props}>
                {/* DATE RANGE SELECT */}
                <El width={1} className='form-field'>
                  <LabelDS {...getLabelProps()}>Pickup - Drop off time</LabelDS>
                  <InputDS
                    type='button'
                    {...getInputProps({ value: inputValue, placeholder: 'When' })}
                    {...getToggleButtonProps()}
                  />
                  <CheckboxDownDS />
                </El>
                {/* DATE RANGE CALENDAR POPUP */}
                <FlexEl width={[1, 1, 550]}>
                  <MenuDS isOpen={isOpen} {...getMenuProps()} id='daterange-selectbox'>
                    <TimeSlider label='Pickup time' value={pickupTime} onChange={setSearchPickupTime} />
                    <TimeSlider label='Drop off time' value={dropOffTime} onChange={setSearchDropOffTime} />
                    <HrEl width={0.9} color={themeGet('colors.blueGrey.1')} />
                    <DateRangePicker />
                    <FlexEl flexDirection='row-reverse' justifyContent='right' py={2} px={3}>
                      <SearchButton
                        type='button'
                        onClick={() => {
                          closeMenu()
                          GA.dateSelectionFinishedOnDesktop()
                        }}
                      >
                        DONE
                      </SearchButton>
                    </FlexEl>
                  </MenuDS>
                </FlexEl>
              </SelectContainerDS>
            )
          }}
        </Downshift>
      )
    }
  }
)`
  #daterange-selectbox {
    max-height: unset;
    ${SearchButton} {
      font-size: 16px;
      padding: 10px 40px;
    }
  }
  ${MenuDS} {
    width: 100%;
    text-align: center;
  }
`

export default connect(selectSearchArgs, { setSearchPickupTime, setSearchDropOffTime })(DateTimeRangeForm)
