import { El, FlexEl, SearchButton } from '../layout'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import ReactLoading from 'react-loading'
import { ageItems } from '../../util/searchArgs'
import { themeGet } from 'styled-system'
import {
  setTagFilters,
  setCarSearchFilters,
  setNearMePicked,
  setNearMeLatitude,
  setNearMeLongitude,
  setNearMePermission,
  resetNearMe,
  setSearchAge,
  setSearchDropOffLocation,
  setSearchDropOffTime,
  setSearchPickupLocation,
  setSearchPickupTime,
  setSearchPickupDate,
  setSearchDropOffDate,
  setSameLocation,
  setSortingOption
} from '../../redux/slices'
import GA from '../../services/GA'
import { selectSearchArgs } from '../../redux/selectors'
import OrangeCheck from '../../util/images/icon-check-stroke.svg'
import { AgeSelectMobile } from './MobileFields.js/AgeField'
import DateFieldWithRange from './MobileFields.js/DateFieldWithRange'
import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import LocationSelect from './MobileFields.js/LocationSelect'
import SearchTabs from './../../components/SearchTabs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NEAR_ME } from '../../constants'

const SwitchButton = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.35)',
    borderRadius: '14px'
  },
  switchBase: {
    color: 'grey',
    padding: 1,
    '&$checked': {
      backgroundImage: 'url(https://www.pngwing.com/en/free-png-yamdv)',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgrounSsize: 'cover',
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none'
      }
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff'
    }
  },
  thumb: {
    width: 24,
    height: 24
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  focusVisible: {}
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  )
})

const CheckBoxWrapper = styled.div`
  cursor: pointer;
  margin-top: 10px;
  width: 90%;
  margin: 10px auto;
  height: 25px;
  max-height: 25px;
  display: flex;
  align-items: center;
`

const CheckBox = styled.div`
  height: 20px;
  width: 20px;
  background-color: ${themeGet('colors.newColorPrimary')};
  border-radius: 5px;
  display: inline-block;

  // mobile checkbox shadow below
  -webkit-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
  -moz-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
  box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
`

const CheckBoxImg = styled.img`
  height: 12px;
  width: auto;
  display: inline-block;
  margin-left: 3px;
  margin-top: 3px;
`

const CheckboxText = styled.div`
  display: inline-block;
  margin-left: 10px;
  margin-top: 0;
  color: ${(props) => (props.isPageDynamic ? 'black' : 'white')};
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

const AgeText = styled.div`
  display: inline-block;
  margin-top: 0;
  color: ${(props) => (props.isPageDynamic ? 'black' : 'white')};
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`

const InputLabel = styled.div`
  color: white;
  padding-left: 5px;
  text-transform: capitalize;
`

const SearchBtn = styled(SearchButton)`
  margin: 0 auto;
  width: 320px !important;
  border-radius: 12px !important;
  font-size: 17px !important;
  font-weight: 600;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif !important;
  text-transform: none !important;
  background-image: linear-gradient(to left, #ffad5e, ${themeGet('colors.colorPrimary')});

  // mobile searchbar search button shadow below
  -webkit-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
  -moz-box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
  box-shadow: ${(props) => (props.isPageDynamic ? '0px 4px 4px 0px rgba(0,0,0,0.25)' : 'none')};
`

const TitlePickupDropoff = styled.div`
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.isPageDynamic ? 'black' : 'white')};
  margin: 4px auto;
  font-weight: 500;
  width: 90%;
`

const DriverAgeButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
`

const RawMobileForm = styled(
  ({
    isPageDynamic,
    pickupLocation,
    dropOffLocation,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    ageOption,
    onSubmit,
    locationSearch,
    nearMePicked,
    setSearchPickupLocation,
    setSearchDropOffLocation,
    setSearchAge,
    setSearchPickupDate,
    setSearchDropOffDate,
    setSearchPickupTime,
    setSearchDropOffTime,
    setSameLocation,
    setNearMeLatitude,
    setNearMeLongitude,
    setNearMePicked,
    setNearMePermission,
    ...props
  }) => {
    const [isSameLocation, setIsSameLocation] = useState(true)
    const [isAge25Above, setIsAge25Above] = useState(true)
    // This is temporary. When full localization is achieved, this should be removed
    const [display, setDisplay] = useState(true)
    const language = useSelector((state) => state.user.language)
    const { t } = useTranslation()

    useEffect(() => {
      setDisplay(!['it', 'es', 'de', 'fr'].includes(language))
    }, [language])

    useEffect(() => {
      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
      mobileSearchForm.classList.add('FLOWACTIVE')
      isSameLocation ? setSameLocation(true) : setSameLocation(false)
      return () => mobileSearchForm.classList.remove('FLOWACTIVE')
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const SameLocationButton = (
      <CheckBoxWrapper
        onClick={() => {
          setIsSameLocation((prevState) => !prevState)
          setSameLocation(!isSameLocation)
        }}
      >
        <CheckBox isPageDynamic={isPageDynamic}>
          {isSameLocation && <CheckBoxImg src={OrangeCheck} alt='orange-check-icon' />}
        </CheckBox>
        <CheckboxText isPageDynamic={isPageDynamic}>{t('homePage:dropOffAtTheSameLocation')}</CheckboxText>
      </CheckBoxWrapper>
    )

    const isSearchPossible = () => {
      if (
        dropOffDate < pickupDate ||
        (dropOffDate.getTime() === pickupDate.getTime() && dropOffTime.hour < pickupTime.hour) ||
        (dropOffDate.getTime() === pickupDate.getTime() &&
          dropOffTime.hour === pickupTime.hour &&
          dropOffTime.min <= pickupTime.min)
      )
        return false // dropoff date/time should be later than pickup date
      if (
        pickupLocation === undefined ||
        pickupLocation === null ||
        dropOffLocation === undefined ||
        dropOffLocation === null
      )
        return false // locations should be valid

      return true
    }

    //Search button loading animation, hide the first button and show the second button.
    const startLoadingAnimation = () => {
      if (isSameLocation) setSearchDropOffLocation(pickupLocation)
      if (!isSearchPossible()) return // check if search is possible before starting spinner on the button

      // SET SEARCH RELATED FILTERS TO INITIAL STATE
      props.setTagFilters([])
      props.setCarSearchFilters({
        carType: [],
        fuel: [],
        transmission: [],
        seats: [],
        cancellationPolicy: [],
        supplier: []
      })
      props.setSortingOption('RECOMMENDED')

      // note: index 0 and 1 are desktop ones where 2 and 3 are mobile ones
      var nodes = document.querySelectorAll('.car-search-button')
      nodes[0].style.display = 'none' //hide first button
      nodes[1].style.display = 'block' //show second button
    }

    const styleLocation = {
      width: '100%',
      transition: 'all 0.1s ease-in-out',
      transform: isSameLocation ? 'scaleY(0)' : '',
      transformOrigin: 'bottom',
      opacity: isSameLocation ? '0' : '1',
      maxHeight: isSameLocation ? '0' : '999999px'
    }

    const styleAge = {
      transition: 'all 0.1s ease-in-out',
      transform: isAge25Above ? 'scaleY(0)' : '',
      maxHeight: isAge25Above ? '0' : '999999px',
      transformOrigin: 'top',
      opacity: isAge25Above ? '0' : '1'
    }

    const handleChange = (val) => {
      GA.selectPickupLocation(val.label)

      if (nearMePicked && val.value !== NEAR_ME) resetNearMe()

      setSearchPickupLocation(val)

      if (val.value === NEAR_ME) handleGeolocation()
    }

    const handleGeolocation = () => {
      const success = (position) => {
        setNearMePicked(true)
        setNearMePermission(true)
        setNearMeLatitude(position.coords.latitude)
        setNearMeLongitude(position.coords.longitude)
      }

      const error = (err) => {
        setNearMePermission(false)
        this.handleLocationChange(null)
      }

      navigator.geolocation.getCurrentPosition(success, error)
    }

    return (
      <El id='MOBILE-SEARCH-FORM' as='form' onSubmit={onSubmit} {...props}>
        {display && <SearchTabs />}
        <TitlePickupDropoff isPageDynamic={isPageDynamic}>{t('homePage:pickUp')}</TitlePickupDropoff>

        <FlexEl className='form-field-container'>
          <LocationSelect
            isPageDynamic={isPageDynamic}
            isSameLocation={isSameLocation}
            explanation={t('homePage:pickUpLocation')}
            label={<InputLabel>{'Pick up'}</InputLabel>}
            value={pickupLocation}
            onChange={(value) => handleChange(value)}
            inputId='pick-up-location-input'
            {...locationSearch}
          />
        </FlexEl>

        <FlexEl className='form-field-container'>
          <DateFieldWithRange
            isPageDynamic={isPageDynamic}
            pickupDate={pickupDate}
            dropOffDate={dropOffDate}
            explanation={t('homePage:pickUpTime')}
            date={pickupDate}
            time={pickupTime}
            label='Pickup time'
            onDateChange={(value) => {
              GA.selectedPickupDateOnMobile()
              setSearchPickupDate(value)
            }}
            onTimeChange={(value) => {
              GA.selectedPickupTimeOnMobile()
              setSearchPickupTime(value)
            }}
          />
        </FlexEl>

        <TitlePickupDropoff isPageDynamic={isPageDynamic}>{t('homePage:dropOff')}</TitlePickupDropoff>

        <FlexEl className='form-field-container' style={styleLocation}>
          <LocationSelect
            isPageDynamic={isPageDynamic}
            idForClick={'DropOffInput'}
            explanation={t('homePage:dropOffLocation')}
            value={dropOffLocation}
            onChange={(value) => {
              GA.selectDropOffLocation(value.label)
              setSearchDropOffLocation(value)
            }}
            label={<InputLabel>{'Drop Off'}</InputLabel>}
            {...locationSearch}
            inputId='dropp-off-location-input'
            {...locationSearch}
          />
        </FlexEl>

        <FlexEl className='form-field-container'>
          <DateFieldWithRange
            isPageDynamic={isPageDynamic}
            isAge25Above={isAge25Above}
            idForClick={'DateInput'}
            pickupDate={pickupDate}
            dropOffDate={dropOffDate}
            explanation={t('homePage:dropOffTime')}
            date={dropOffDate}
            time={dropOffTime}
            label='Drop off time'
            onDateChange={(value) => {
              GA.selectedDropOffDateOnMobile()
              setSearchDropOffDate(value)
            }}
            onTimeChange={(value) => {
              GA.selectedDropOffTimeOnMobile()
              setSearchDropOffTime(value)
            }}
            after={pickupDate}
          />
        </FlexEl>

        {SameLocationButton}

        <DriverAgeButtonWrapper>
          <AgeText isPageDynamic={isPageDynamic}>{t('homePage:age25')}</AgeText>
          <SwitchButton
            checked={isAge25Above}
            onChange={() => {
              setIsAge25Above((prevState) => !prevState)
              setSearchAge(!isAge25Above ? 26 : 21)
            }}
            name='age-btn'
          />
        </DriverAgeButtonWrapper>

        {/* AGE SELECT */}
        <El style={styleAge} mb={15}>
          <AgeSelectMobile
            isPageDynamic={isPageDynamic}
            value={ageOption}
            onChange={(opt) => setSearchAge(opt.value)}
            items={ageItems.slice(0, 7)}
            label='Driver age'
            placeholder='Age'
          />
        </El>
        {/* SEARCH BUTTON */}
        <FlexEl id='search-button-homepage' className='form-field-container'>
          <SearchBtn
            className='car-search-button'
            type='submit'
            onClick={startLoadingAnimation}
            isPageDynamic={isPageDynamic}
          >
            <El as='span'>{t('homePage:seeRentalCarPrices')}</El>
          </SearchBtn>
          <SearchBtn
            className='car-search-button'
            style={{ display: 'none' }}
            type='submit'
            isPageDynamic={isPageDynamic}
          >
            <ReactLoading className='loading-animation' type={'spin'} height={30} width={30} />
          </SearchBtn>
        </FlexEl>
      </El>
    )
  }
)`
  width: 100%;
  .form-field-container {
    width: 100%;
    margin-bottom: 8px;
  }
  .form-title {
    font-size: 18px;
    color: white;
  }
  .car-search-button {
    width: 100%;
    font-size: 18px;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: -8px;
  }
  .loading-animation {
    position: relative;
    left: 46%;
  }
  #search-button-homepage {
    margin-top: 20px;
  }
`

const SearchBarMobile = connect(selectSearchArgs, {
  setSearchPickupLocation,
  setSearchDropOffLocation,
  setSearchAge,
  setSearchPickupDate,
  setSearchDropOffDate,
  setSearchPickupTime,
  setSearchDropOffTime,
  setCarSearchFilters,
  setTagFilters,
  setSortingOption,
  setNearMePicked,
  setNearMeLatitude,
  setNearMeLongitude,
  setNearMePermission,
  resetNearMe,
  setSameLocation
})(RawMobileForm)

export default SearchBarMobile
