import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import crashedCar from '../../util/images/crashed-car-web.webp'
import crashedCarMobile from '../../util/images/crashed-car-mobile.webp'
import { El, LinkEl } from './../../components/layout'
import NoSSR from '../../components/NoSSR'
import { useTranslation } from 'react-i18next'

const PrimaryButton = styled(LinkEl)`
  color: white;
  background: ${themeGet('colors.colorPrimary')};
  color: white;
  border: 1px solid ${themeGet('colors.colorPrimary')};
`

const PageContainer = styled.div`
  max-width: 1120px;
  margin: 30px auto 20px;
  justify-content: space-around;
  list-style-type: none;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const PageTitle = styled.h1`
  color: #263238;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 48px;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 16px;
  }
`

const PageText = styled.p`
  color: #263238;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 48px;
  font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    font-size: 16px;
    max-width: 80%
    margin: 20px auto 48px;
  }
`

const ButtonGroups = styled.div`
  display: flex;
  gap: 24px;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    gap: 15px;
    justify-content: center;
    margin-bottom: 20px;
  }
  a {
    border-radius: 12px;
    padding: 10px 16px;
    display: block;
    max-height: 50px;
    width: 156px;
    line-height: 25px;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Quicksand', 'Quicksand Regular', sans-serif;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
`

const ErrorImage = styled.div`
  margin-bottom: 36px;
  display: block;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: none;
  }
`

const LeftContainer = styled.div`
  width: 45%;
  padding-top: 80px;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    width: 100%;
    text-align: center;
    padding-top: 30px;
  }
`

const Image = styled.img`
  max-width: 100%;
`

const FlexStyled = styled.div`
  display: flex;
  box-sizing: border-box;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    flex-direction: column;
  }
`

const MobileImage = styled.div`
  display: none;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: block;
    svg,
    img {
      max-width: 60%;
      margin: 0 auto;
    }
  }
`

const DesktopImage = styled.div`
  display: block;
  @media screen and (max-width: ${themeGet('breakpoints.1')}px) {
    display: none;
  }
`

function PageNotFound() {
  const { t: TRANSLATOR } = useTranslation()
  return (
    <>
      <Helmet>
        <title>Page not found | Carla Car Rental</title>
        <meta name='description' content='Page not found' />
      </Helmet>
      <El bg='newColorPrimary'>
        <PageContainer>
          <FlexStyled>
            <LeftContainer>
              <ErrorImage>
                <svg width='529' height='206' viewBox='0 0 529 206' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M92.9 191.28V167.78C92.9 161.97 88.02 157.25 81.99 157.25H10.91C4.89 157.25 0 152.54 0 146.72V123.05C0 120.86 0.710004 118.72 2.02 116.94L82.45 8.01996C84.5 5.24996 87.81 3.59998 91.33 3.59998H123.45C129.47 3.59998 134.36 8.31 134.36 14.13V111.85C134.36 117.66 139.24 122.38 145.27 122.38H146.9C153.41 122.38 158.47 127.85 157.74 134.1L156.11 147.92C155.48 153.24 150.82 157.26 145.27 157.26C139.25 157.26 134.36 161.97 134.36 167.79V191.29C134.36 197.1 129.48 201.82 123.45 201.82H103.8C97.78 201.81 92.9 197.09 92.9 191.28ZM92.9 87.1C92.9 84.89 92.9 82.7799 92.9 80.7599C92.91 70.2799 78.79 66.2299 72.85 75.0099C66.09 85.0099 58.58 95.73 51.36 105.9C46.4 112.88 51.59 122.38 60.36 122.38H81.99C88.01 122.38 92.9 117.67 92.9 111.85V87.1Z'
                    fill='url(#paint0_linear_40_7558)'
                  />
                  <path
                    d='M463.9 191.68V168.18C463.9 162.37 459.02 157.65 452.99 157.65H381.91C375.89 157.65 371 152.94 371 147.12V123.45C371 121.26 371.71 119.12 373.02 117.34L453.45 8.41998C455.5 5.64998 458.81 4 462.33 4H494.45C500.47 4 505.36 8.71003 505.36 14.53V112.25C505.36 118.06 510.24 122.78 516.27 122.78H517.9C524.41 122.78 529.47 128.25 528.74 134.5L527.11 148.32C526.48 153.64 521.82 157.66 516.27 157.66C510.25 157.66 505.36 162.37 505.36 168.19V191.69C505.36 197.5 500.48 202.22 494.45 202.22H474.8C468.78 202.21 463.9 197.49 463.9 191.68ZM463.9 87.5C463.9 85.29 463.9 83.18 463.9 81.16C463.91 70.68 449.79 66.63 443.85 75.41C437.09 85.41 429.58 96.13 422.36 106.3C417.4 113.28 422.59 122.78 431.36 122.78H452.99C459.01 122.78 463.9 118.07 463.9 112.25V87.5Z'
                    fill='url(#paint1_linear_40_7558)'
                  />
                  <path
                    d='M343.14 101.47C343.14 155.97 325.2 205.42 264.22 205.42C203.98 205.42 185.34 157.6 185.34 102.71C185.34 48.38 206.02 1.6586e-06 265.16 1.6586e-06C322.42 -0.00999834 343.14 45.2 343.14 101.47ZM231.4 102.21C231.4 138.64 235.71 171.07 264.78 171.07C293.14 171.07 296.86 140.75 296.86 101.73C296.86 63.25 292.04 34.34 265.21 34.34C238.96 34.34 231.4 59.3 231.4 102.21Z'
                    fill='url(#paint2_linear_40_7558)'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_40_7558'
                      x1='18.2039'
                      y1='-45.6124'
                      x2='205.176'
                      y2='0.718122'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                    <linearGradient
                      id='paint1_linear_40_7558'
                      x1='389.204'
                      y1='-45.2124'
                      x2='576.176'
                      y2='1.11815'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                    <linearGradient
                      id='paint2_linear_40_7558'
                      x1='203.543'
                      y1='-51'
                      x2='391.252'
                      y2='-6.11995'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                  </defs>
                </svg>
              </ErrorImage>
              <MobileImage>
                <svg width='180' height='71' viewBox='0 0 180 71' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M31.6218 65.3679V57.337C31.6218 55.3515 29.9607 53.7385 27.9082 53.7385H3.71361C1.66449 53.7385 0 52.1289 0 50.14V42.0511C0 41.3027 0.241675 40.5713 0.68758 39.963L28.0648 2.74083C28.7626 1.79421 29.8893 1.23035 31.0874 1.23035H42.0206C44.0697 1.23035 45.7342 2.83995 45.7342 4.82887V38.2236C45.7342 40.2091 47.3953 41.8221 49.4478 41.8221H50.0026C52.2185 41.8221 53.9409 43.6914 53.6924 45.8273L53.1376 50.5501C52.9231 52.3682 51.3369 53.7419 49.4478 53.7419C47.3987 53.7419 45.7342 55.3515 45.7342 57.3405V65.3713C45.7342 67.3568 44.0731 68.9698 42.0206 68.9698H35.332C33.2829 68.9664 31.6218 67.3534 31.6218 65.3679ZM31.6218 29.7655C31.6218 29.0103 31.6218 28.2892 31.6218 27.5989C31.6252 24.0175 26.819 22.6334 24.7971 25.6339C22.4961 29.0513 19.9398 32.7147 17.4822 36.1902C15.7939 38.5756 17.5605 41.8221 20.5457 41.8221H27.9082C29.9573 41.8221 31.6218 40.2125 31.6218 38.2236V29.7655Z'
                    fill='url(#paint0_linear_40_8948)'
                  />
                  <path
                    d='M157.907 65.5045V57.4736C157.907 55.4881 156.246 53.8751 154.193 53.8751H129.999C127.95 53.8751 126.285 52.2655 126.285 50.2766V42.1877C126.285 41.4392 126.527 40.7079 126.973 40.0996L154.35 2.87742C155.048 1.93081 156.174 1.36694 157.373 1.36694H168.306C170.355 1.36694 172.019 2.97654 172.019 4.96546V38.3602C172.019 40.3457 173.68 41.9587 175.733 41.9587H176.288C178.504 41.9587 180.226 43.828 179.978 45.9639L179.423 50.6867C179.208 52.5048 177.622 53.8785 175.733 53.8785C173.684 53.8785 172.019 55.4881 172.019 57.4771V65.5079C172.019 67.4934 170.358 69.1064 168.306 69.1064H161.617C159.568 69.103 157.907 67.49 157.907 65.5045ZM157.907 29.9021C157.907 29.1469 157.907 28.4258 157.907 27.7355C157.91 24.1541 153.104 22.77 151.082 25.7705C148.781 29.1879 146.225 32.8513 143.767 36.3268C142.079 38.7122 143.846 41.9587 146.831 41.9587H154.193C156.242 41.9587 157.907 40.3491 157.907 38.3602V29.9021Z'
                    fill='url(#paint1_linear_40_8948)'
                  />
                  <path
                    d='M116.801 34.6762C116.801 53.301 110.694 70.2 89.9375 70.2C69.4327 70.2 63.0879 53.858 63.0879 35.1C63.0879 16.5333 70.1271 5.6681e-07 90.2575 5.6681e-07C109.748 -0.00341682 116.801 15.4466 116.801 34.6762ZM78.766 34.9291C78.766 47.3787 80.2331 58.4613 90.1281 58.4613C99.7814 58.4613 101.048 48.0997 101.048 34.7651C101.048 21.615 99.407 11.7353 90.2745 11.7353C81.3394 11.7353 78.766 20.2651 78.766 34.9291Z'
                    fill='url(#paint2_linear_40_8948)'
                  />
                  <defs>
                    <linearGradient
                      id='paint0_linear_40_8948'
                      x1='6.19635'
                      y1='-15.5875'
                      x2='69.8681'
                      y2='0.127533'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                    <linearGradient
                      id='paint1_linear_40_8948'
                      x1='132.482'
                      y1='-15.4509'
                      x2='196.153'
                      y2='0.264129'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                    <linearGradient
                      id='paint2_linear_40_8948'
                      x1='69.2838'
                      y1='-17.4287'
                      x2='133.205'
                      y2='-2.20616'
                      gradientUnits='userSpaceOnUse'
                    >
                      <stop stopColor='#FFA659' />
                      <stop offset='1' stopColor='#FF861E' stopOpacity='0.96' />
                    </linearGradient>
                  </defs>
                </svg>
              </MobileImage>
              <PageTitle>{TRANSLATOR('404:header')}</PageTitle>
              <NoSSR>
                <MobileImage>
                  <Image alt='page-not-found' src={crashedCarMobile} />
                </MobileImage>
              </NoSSR>
              <PageText>{TRANSLATOR('404:text')}</PageText>
              <ButtonGroups>
                <PrimaryButton as={Link} to='/'>
                  {TRANSLATOR('404:back')}
                </PrimaryButton>
              </ButtonGroups>
            </LeftContainer>
            <NoSSR>
              <DesktopImage>
                <Image alt='page-not-found' src={crashedCar} />
              </DesktopImage>
            </NoSSR>
          </FlexStyled>
        </PageContainer>
      </El>
    </>
  )
}

export default PageNotFound
