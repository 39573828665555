import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  country: null,
  deviceId: null,
  carlinDeviceId: null,
  language: 'en',
  sharedSecret: null,
  authKey: null
}

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUserCountry: (state, action) => {
      state.country = action.payload
    },
    setSharedSecret: (state, action) => {
      state.sharedSecret = action.payload
    },
    setDeviceId: (state, action) => {
      state.deviceId = action.payload
    },
    setAuthKey: (state, action) => {
      state.authKey = action.payload
    },
    setCarlinDeviceId: (state, action) => {
      state.carlinDeviceId = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    }
  }
})

export const { setUserCountry, setSharedSecret, setDeviceId, setAuthKey, setCarlinDeviceId, setLanguage } =
  userSlice.actions

export default userSlice.reducer
