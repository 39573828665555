import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { El, FlexEl, SearchButton } from '../../layout'
import { CarlaSelect } from '../../CarlaSelect'
import LocationForm from './LocationForm'
import DateTimeRangeForm from './DateTimeRangeForm'
import { setSearchAge } from '../../../redux/slices'
import { ageItems } from '../../../util/searchArgs'
import { selectSearchArgs } from '../../../redux/selectors'
import { SearchIcon } from '../../svg'
import ReactLoading from 'react-loading'

const DesktopForm = styled(
  ({
    pickupLocation,
    dropOffLocation,
    pickupDate,
    dropOffDate,
    pickupTime,
    dropOffTime,
    dateRange,
    ageOption,
    setSearchAge,
    onSubmit,
    ...props
  }) => {
    const isSearchPossible = () => {
      if (
        dropOffDate < pickupDate ||
        (dropOffDate.getTime() === pickupDate.getTime() && dropOffTime.hour < pickupTime.hour) ||
        (dropOffDate.getTime() === pickupDate.getTime() &&
          dropOffTime.hour === pickupTime.hour &&
          dropOffTime.min <= pickupTime.min)
      )
        return false // dropoff date/time should be later than pickup date
      if (
        pickupLocation === undefined ||
        pickupLocation === null ||
        dropOffLocation === undefined ||
        dropOffLocation === null
      )
        return false // locations should be valid

      return true
    }

    //Search button loading animation, hide the first button and show the second button.
    const startLoadingAnimation = () => {
      if (!isSearchPossible()) return // check if search is possible before starting spinner on the button

      var nodes = document.querySelectorAll('.car-search-button')
      // 0 and 1 are mobile buttons
      nodes[2].style.display = 'none' //hide first button
      nodes[3].style.display = 'block' //show second button
    }

    return (
      <FlexEl show={[0, 1, 1]} width={[1, 1, 1120]} as='form' onSubmit={onSubmit} {...props}>
        <FlexEl width={1} className='form-title'>
          Find your car
        </FlexEl>
        <FlexEl width={[1, 1, 0.385]} mb={[25, 25, 0]} className='form-field-container'>
          <LocationForm />
        </FlexEl>
        <FlexEl width={[1, 1, 0.34]} mb={[25, 25, 0]} className='form-field-container'>
          <DateTimeRangeForm />
        </FlexEl>
        <El width={[1, 1, 0.145]} mb={[25, 25, 0]} className='form-field-container'>
          <CarlaSelect
            items={ageItems}
            value={ageOption}
            onChange={(opt) => setSearchAge(opt.value)}
            label='Driver age'
            placeholder='Age'
          />
        </El>
        <El width={[1, 1, 0.13]}>
          <SearchButton className='car-search-button' type='submit' onClick={startLoadingAnimation}>
            <SearchIcon />
            <El as='span' className='button-text'>
              Search
            </El>
          </SearchButton>
          <SearchButton className='car-search-button' style={{ display: 'none' }} type='submit'>
            <ReactLoading className='loading-animation' type={'spin'} height={30} width={30} />
          </SearchButton>
        </El>
      </FlexEl>
    )
  }
)`
  min-height: 198px;
  padding: 24px 24px 18px 24px;
  object-fit: contain;
  flex-wrap: wrap;
  justify-content: space-between;
  border: solid 1px #dfdfe7;
  border-radius: 12px;
  background-color: white;
  .form-field-container {
    // upper elements will have priority
    &:nth-child(1) {
      z-index: 10;
    }
    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
    padding-right: 10px;
  }
  .form-title {
    width: 100%;
    height: 40px;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #525266;
  }
  .form-field {
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    &:focus,
    &.focus {
      z-index: 3;
    }
    &.left input,
    &.left button {
      border-radius: 9px 0 0 9px;
    }
    &.right input,
    &.right button {
      border-radius: 0 9px 9px 0;
      margin-left: -1px;
    }
  }
  .plane {
    fill: white;
  }

  .car-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 18px;
    height: 50px;
    margin-top: 20px;
  }

  .button-text {
    margin-left: 5px;
  }

  .loading-animation {
    position: relative;
    left: 38%;
    @media screen and (max-width: 1200px) {
      left: 50%;
    }
  }
`

const SearchPreferenceBox = styled(
  ({ pickupLocation, dropOffLocation, dateRange, ageOption, setSearchAge, onSubmit, ...props }) => {
    return (
      <FlexEl as='form' onSubmit={onSubmit} {...props}>
        <FlexEl className='form-field-container'>
          <LocationForm />
        </FlexEl>
        <FlexEl className='form-field-container'>
          <DateTimeRangeForm />
        </FlexEl>
        <FlexEl className='form-field-container'>
          <CarlaSelect
            items={ageItems}
            value={ageOption}
            onChange={(opt) => setSearchAge(opt.value)}
            label='Driver age'
            placeholder='Age'
          />
        </FlexEl>
        <FlexEl width={1}>
          <SearchButton id='car-search-button' type='submit'>
            <FlexEl my='auto' flexDirection='row'>
              <SearchIcon my='auto' />
              <El my='auto' className='button-text'>
                Search
              </El>
            </FlexEl>
          </SearchButton>
        </FlexEl>
      </FlexEl>
    )
  }
)`
  width: 100%;
  padding: 0 16px 16px 16px;
  flex-wrap: wrap;
  border-radius: 12px;
  background-color: #273c75;
  .form-field-container {
    width: 100%;
    margin-top: 12px;
    // upper elements will have priority
    &:nth-child(1) {
      z-index: 10;
      margin-top: 0;
    }
    &:nth-child(2) {
      z-index: 9;
    }
    &:nth-child(3) {
      z-index: 8;
    }
  }
  .form-field {
    z-index: 1;
    &:hover {
      z-index: 2;
    }
    &:focus,
    &.focus {
      z-index: 3;
    }
    &.left input,
    &.left button {
      border-radius: 9px 0 0 9px;
    }
    &.right input,
    &.right button {
      border-radius: 0 9px 9px 0;
      margin-left: -1px;
    }
  }
  .plane {
    fill: white;
  }
  #car-search-button {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: center;
    margin-top: 20px;
  }
  .button-text {
    margin-left: 5px;
  }
`

export const SearchPreferenceForm = connect(selectSearchArgs, { setSearchAge })(SearchPreferenceBox)

export default connect(selectSearchArgs, { setSearchAge })(DesktopForm)
