import React, { Fragment } from 'react'
import debug from 'debug'
import util from './index'
import API from '../services/API'
import { store as reduxStore } from '../redux/store'
import { updateSearchDateRange } from '../redux/slices'
import { isArray } from 'lodash'

const log = debug('carla:search')

export const defaultAge = { label: '25 and above', value: '25' }
export const ageItems = [
  ...['18', '19', '20', '21', '22', '23', '24'].map((num) => ({ label: num, value: num })),
  defaultAge
]

/**
 * asynchronous location search on search form input change
 * @param input {string}
 * @returns {Promise<{value, label, type}>}
 */
export const loadLocationItems = async (input) => {
  if (!input) return []
  try {
    const locations = await API.searchPlaces(input)
    log(`found ${locations.length} results for "${input}":`, locations)
    return locations['data']?.slice(0, 25)?.map((r) => ({ value: r.id, label: r.prettyName, type: r.type }))
  } catch (err) {
    console.error('something wrong happened searching locations', err)
    return null
  }
}

/**
 * asynchronous location search on search form input change
 * @param input {string}
 * @returns {Promise<{value, label, type}>}
 */
export const loadHotelLocationItems = async (input) => {
  try {
    const locations = await API.getHotelLocations(input)
    log(`found ${locations.length} hotel results for "${input}":`, locations)
    if (!isArray(locations)) return []
    return locations?.map((r) => ({ value: { lat: r.latitude, long: r.longitude }, label: r.name, type: r.type }))
  } catch (err) {
    console.error('something wrong happened searching locations', err)
    return null
  }
}

/**
 * remove area type locations
 * @param locations {{value, label, type}}
 */
export const filterDropOffLocations = (locations) => {
  if (!locations) return null
  return locations.filter((location) => location.type !== 'area')
}

// make matching part of the location results bold
export const formatItem = (item, inputValue) => {
  const searchStartIndex = item.label.toLowerCase().search(inputValue)
  if (searchStartIndex === -1) {
    return <div>{item.label}</div>
  }
  const searchEndIndex = searchStartIndex + inputValue.length

  const preBold = item.label.substring(0, searchStartIndex)
  const bold = item.label.substring(searchStartIndex, searchEndIndex)
  const postBold = item.label.substring(searchEndIndex)

  return (
    <Fragment>
      {preBold}
      {bold && <b>{bold}</b>}
      {postBold}
    </Fragment>
  )
}

/**
 * get car search state object from url parameters
 * @param urlParams {{pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age}}
 * @param oldState {{dropOffLocation, dropOffDateStr, pickupDateStr, pickupLocation, age}}
 * @returns {{
 *  pickupLocation: {label, value}, dropOffLocation: {label, value},
 *  pickupDateStr, dropOffDateStr, pickupTime, dropOffTime age
 * }}
 */
export const getStateFromUrlParams = (urlParams, oldState) => {
  const { pickupLocationId, dropOffLocationId, pickupDateStr, dropOffDateStr, age } = urlParams
  const newState = {
    pickupLocation: {
      label: '',
      value: pickupLocationId
    },
    dropOffLocation: {
      label: '',
      value: dropOffLocationId
    },
    pickupDateStr: util.getDateFromAPI(pickupDateStr).toString(),
    dropOffDateStr: util.getDateFromAPI(dropOffDateStr).toString(),
    pickupTime: util.getTimeObjFromAPI(pickupDateStr),
    dropOffTime: util.getTimeObjFromAPI(dropOffDateStr),
    age: age ? age : '26'
  }

  // update location labels if they match with news
  if (oldState) {
    if (oldState.pickupLocation && oldState.pickupLocation.value === newState.pickupLocation.value) {
      newState.pickupLocation.label = oldState.pickupLocation.label
    }
    if (oldState.dropOffLocation && oldState.dropOffLocation.value === newState.dropOffLocation.value) {
      newState.dropOffLocation.label = oldState.dropOffLocation.label
    }
  }

  const pickupDatePassed = new Date().getTime() > new Date(newState.pickupDateStr).getTime()
  const dropOffDatePassed = new Date().getTime() > new Date(newState.dropOffDateStr).getTime()
  if (pickupDatePassed || dropOffDatePassed) {
    const defaultDateRange = util.getDefaultDateRange()
    newState.pickupDateStr = defaultDateRange.pickupDate.toString()
    newState.dropOffDateStr = defaultDateRange.dropOffDate.toString()
  }

  return newState
}

// use default date range if initial request
export const checkDefaultDateRange = () => {
  const {
    searchArgs: { pickupDateStr, dropOffDateStr }
  } = reduxStore.getState()
  const now = new Date().getTime()
  const pickupDatePassed = now > new Date(pickupDateStr).getTime()
  const dropOffDatePassed = now > new Date(dropOffDateStr).getTime()
  if (!pickupDateStr || !dropOffDateStr || pickupDatePassed || dropOffDatePassed) {
    reduxStore.dispatch(updateSearchDateRange(util.getDefaultDateRange()))
  }
}

// use default date range if initial request
export const checkNewDefaultDateRange = () => {
  const {
    searchArgs: { pickupDateStr, dropOffDateStr }
  } = reduxStore.getState()
  const now = new Date().getTime()
  const pickupDatePassed = now > new Date(pickupDateStr).getTime()
  const dropOffDatePassed = now > new Date(dropOffDateStr).getTime()
  if (!pickupDateStr || !dropOffDateStr || pickupDatePassed || dropOffDatePassed) {
    reduxStore.dispatch(updateSearchDateRange(util.getNewDefaultDateRange()))
  }
}
