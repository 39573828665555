import React, { useEffect } from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Slider from '../../common/SlickSlider'
import SessionManager from '../../../services/SessionManager'
import { useTranslation } from 'react-i18next'

const TimeSelectorContainer = styled.div`
  position: fixed;
  top: -5vh;
  left: -5vw;
  top: 0vh;
  left: 0vw;
  width: 100vw;
  height: 150vh;
  //background-color: rgba(0, 0, 0, 0.6);
  z-index: 99999;
  //overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

const PageBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99998; /* Under Modal */
  visibility: ${(props) => (props.showPopUp ? 'visible' : 'hidden')};
  transition: visibility 0.2s ease-in-out;
`

const TimeSelectorWrapper = styled.div`
  width: 90vw;
  height: 350px;
  background: white;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 35vh;
  border-radius: 12px;
  max-width: 500px;
  position: relative;

  &::before {
    position: absolute;
    top: 150px;
    left: 0;
    content: '';
    width: 100%;
    height: 30px;
    border-bottom: 2px solid ${themeGet('colors.colorPrimary')};
    border-top: 2px solid ${themeGet('colors.colorPrimary')};
  }
`
const TimeSelectorTitle = styled.div`
  width: 95vw;
  height: 70px;
  background-color: ${themeGet('colors.colorPrimary')};
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 500px;
`

const SlickerWrapper = styled.div`
  width: 80vw;
  height: 210px;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20%;
  max-width: 500px;
`

const DoneButton = styled.button`
  border: none;
  background-color: white;
  color: ${themeGet('colors.colorPrimary')};
  font-size: 22px;
  margin-left: auto;
  margin-right: 5vw;
  font-weight: 700;
  cursor: pointer;
`

const HourOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
const EuropeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 0]
const MinuteOptions = ['00', '30', '00', '30', '00', '30', '00', '30', '00', '30', '00', '30']
const TimeIntervalOptions = ['AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM', 'AM', 'PM']

const HourSlicker = styled.div`
  display: inline-block;
  height: 100px;
  width: 20vw;
  font-size: 25px;
  transform: translateY(-50px);
  position: relative;
  max-width: 150px;
  position: relative;

  &::after {
    position: absolute;
    top: 64px;
    right: 30%;
    content: ' : ';
    width: 20px;
    height: 20px;
  }
`

const MinuteSlicker = styled.div`
  display: inline-block;
  width: 20vw;
  height: 100px;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 150px;
`

const TimeIntervalSlicker = styled.div`
  display: inline-block;
  width: 20vw;
  font-size: 25px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 150px;
`

function TimeSelect({ showPopUp, isAge25Above, onTimeChange, title, closeFunction, defaultTime = '12:00 PM' }) {
  const [defaultHour, defaultMinute, defaultAmPm] = defaultTime.split(/:| /)
  const region = SessionManager.getUserCountry()
  const { t } = useTranslation()

  const [timeObj, setTimeObj] = useState({
    hour: parseInt(defaultHour),
    min: parseInt(defaultMinute),
    timeInterval: defaultAmPm
  })

  useEffect(() => {
    if (showPopUp) {
      document.body.style.position = 'fixed'
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.removeProperty('overflow')
      document.body.style.position = 'static'
    }
  }, [showPopUp])

  const handleSetTime = (e) => {
    e.preventDefault()

    let hour = timeObj.hour
    const min = timeObj.min === 0 ? '00' : '30'
    const timeInterval = timeObj.timeInterval

    if (region && region === 'US') {
      if (timeObj.hour === 12) {
        if (timeObj.timeInterval === 'AM') {
          hour = 0
        } else {
          hour = 12
        }
      } else {
        if (timeObj.timeInterval === 'PM') {
          hour = hour + 12
        }
      }
    }

    onTimeChange({
      label: `${hour < 10 ? '' : ''}${hour}:${min}`,
      value: hour * 60 + parseInt(timeObj.min),
      hour: hour,
      min: min,
      timeInterval: timeInterval
    })

    closeFunction()

    const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
    if (mobileSearchForm.classList.contains('FLOWACTIVE') && title === 'Pick Up Hour') {
      const dropOffTime = document.getElementById('dropOffTime')
      setTimeout(() => {
        dropOffTime.click()
      }, 500)
    } else if (mobileSearchForm.classList.contains('FLOWACTIVE') && title === 'Drop Off Hour') {
      if (!isAge25Above) {
        const AgeInput = document.getElementById('AgeInput')
        setTimeout(() => {
          AgeInput.click()
        }, 500)
      }

      const mobileSearchForm = document.getElementById('MOBILE-SEARCH-FORM')
      mobileSearchForm.classList.remove('FLOWACTIVE')
    }
  }

  const SliderSettingsHour = {
    draggable: true,
    arrows: false,
    infinite: true,
    slidesToShow: 5,
    initialSlide: 9,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    initialSlide: parseInt(defaultHour - 3, 10),
    afterChange: function (currentSlide) {
      if (region && region === 'US') {
        setTimeObj({ ...timeObj, hour: (currentSlide + 3) % 12 })
      } else {
        setTimeObj({ ...timeObj, hour: (currentSlide + 3) % 24 })
      }
    }
  }

  const SliderSettingsMinute = {
    draggable: true,
    infinite: true,
    arrows: false,
    slidesToShow: 2,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    initialSlide: parseInt(defaultMinute) === 30 ? 1 : 0,
    afterChange: function (currentSlide) {
      setTimeObj({ ...timeObj, min: currentSlide % 2 === 0 ? 0 : 30 })
    }
  }

  const SliderSettingsTimeInterval = {
    draggable: true,
    infinite: true,
    slidesToShow: 2,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    swipeToSlide: true,
    initialSlide: defaultAmPm === 'PM' ? 1 : 0,
    afterChange: function (currentSlide) {
      // even index -> AM odd index -> PM
      setTimeObj({ ...timeObj, timeInterval: currentSlide % 2 == 0 ? 'AM' : 'PM' })
    }
  }

  return (
    <>
      <PageBackground showPopUp={showPopUp} />
      <TimeSelectorContainer
        style={{
          transition: 'all 0.2s ease-in-out',
          transform: showPopUp ? '' : 'scale(0)'
        }}
        showPopUp={showPopUp}
        //onTouchMove={e => e.preventDefault()}
      >
        <TimeSelectorWrapper>
          <TimeSelectorTitle>{title}</TimeSelectorTitle>
          <SlickerWrapper>
            <HourSlicker>
              <Slider {...SliderSettingsHour}>
                {(region && region === 'US' ? HourOptions : EuropeOptions).map((item, index) => (
                  <div style={{ textAlign: 'center' }} key={index}>
                    {item < 10 ? `0${item}` : item}
                  </div>
                ))}
              </Slider>
            </HourSlicker>
            <MinuteSlicker>
              <Slider style={{ margin: '0 auto' }} {...SliderSettingsMinute}>
                {MinuteOptions.map((item, index) => (
                  <div style={{ textAlign: 'center' }} key={index}>
                    {item}
                  </div>
                ))}
              </Slider>
            </MinuteSlicker>
            {region && region === 'US' && (
              <TimeIntervalSlicker>
                <Slider {...SliderSettingsTimeInterval}>
                  {TimeIntervalOptions.map((item, index) => (
                    <div style={{ textAlign: 'center' }} key={index}>
                      {item}
                    </div>
                  ))}
                </Slider>
              </TimeIntervalSlicker>
            )}
          </SlickerWrapper>
          <DoneButton onClick={handleSetTime}>{t('homePage:done')}</DoneButton>
        </TimeSelectorWrapper>
      </TimeSelectorContainer>
    </>
  )
}

export default TimeSelect
