import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { themeGet } from 'styled-system'

export const TabsContainer = styled('div')`
  width: 100%;
  margin-left: auto;
  font-size: 24px;
  padding: 0;
  letter-spacing: 2px;
  display: flex;
  gap: 8px;
  justify-content: space-evenly;
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    gap: 16px;
    justify-content: flex-start;
    width: 95%;
  }
  @media (min-width: ${themeGet('breakpoints.1')}px) {
    width: 100%;
  }
`

export const TabsItem = styled(Link)`
  font-size: 16px;
  letter-spacing: 0;
  font-weight: 700;
  color: #fff;
  display: block;
  padding: 10px 16px;
  border-radius: 8px;
  text-decoration: none;
  background: ${(props) => (props.$activeTab ? 'var(--colorPrimary)' : 'transparent')};
  @media (min-width: ${themeGet('breakpoints.0')}px) {
    gap: 16px;
    padding: 10px 25px;
  }
`
