import React from 'react'
import { withRouter } from 'react-router-dom'
import { TabsContainer, TabsItem } from './SearchTabs.styled'

function SearchTab({ location }) {
  const { pathname } = location

  return (
    <TabsContainer>
      <TabsItem $activeTab={pathname === '/'} to='/'>
        Car
      </TabsItem>
      <TabsItem $activeTab={pathname === '/hotels'} to='/hotels'>
        Hotels
      </TabsItem>
      <TabsItem $activeTab={pathname === '/hotel-bundle'} to='/hotel-bundle'>
        Bundle & Save
      </TabsItem>
    </TabsContainer>
  )
}

export default withRouter(SearchTab)
